import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => {
  return (
    <Layout nofooter>
      <SEO title="Contact Us" />
      <div className="px-4 py-16 overflow-hidden bg-gray-100 sm:px-6 lg:px-8 lg:py-24">
        <div className="relative max-w-xl mx-auto">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 leading-9 sm:text-4xl sm:leading-10">
              Contact Us
            </h2>
          </div>
          <div className="mt-12">
            <form
              action="#"
              method="POST"
              className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
            >
              <div>
                <label
                  htmlFor="first_name"
                  className="block text-sm font-medium text-gray-700 leading-5"
                >
                  First name
                </label>
                <div className="relative mt-1 rounded-md shadow-sm">
                  <input
                    id="first_name"
                    className="block w-full px-4 py-3 form-input transition ease-in-out duration-150"
                    required
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="last_name"
                  className="block text-sm font-medium text-gray-700 leading-5"
                >
                  Last name
                </label>
                <div className="relative mt-1 rounded-md shadow-sm">
                  <input
                    id="last_name"
                    className="block w-full px-4 py-3 form-input transition ease-in-out duration-150"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="company"
                  className="block text-sm font-medium text-gray-700 leading-5"
                >
                  Company
                </label>
                <div className="relative mt-1 rounded-md shadow-sm">
                  <input
                    id="company"
                    className="block w-full px-4 py-3 form-input transition ease-in-out duration-150"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 leading-5"
                >
                  Email
                </label>
                <div className="relative mt-1 rounded-md shadow-sm">
                  <input
                    id="email"
                    type="email"
                    className="block w-full px-4 py-3 form-input transition ease-in-out duration-150"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="phone_number"
                  className="block text-sm font-medium text-gray-700 leading-5"
                >
                  Phone Number
                </label>
                <div className="relative mt-1 rounded-md shadow-sm">
                  <input
                    id="phone_number"
                    className="block w-full px-4 py-3 form-input transition ease-in-out duration-150"
                    placeholder="+1 (555) 987-6543"
                    type="number"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="message"
                  className="block text-sm font-medium text-gray-700 leading-5"
                >
                  Message
                </label>
                <div className="relative mt-1 rounded-md shadow-sm">
                  <textarea
                    id="message"
                    rows="4"
                    className="block w-full px-4 py-3 form-textarea transition ease-in-out duration-150"
                  ></textarea>
                </div>
              </div>
              <div className="sm:col-span-2">
                <span className="inline-flex w-full rounded-md shadow-sm">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center px-6 py-3 text-base font-medium text-white bg-indigo-600 border border-transparent leading-6 rounded-md hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
                  >
                    Let's talk
                  </button>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
